import axios, { AxiosRequestConfig, ResponseType } from "axios";
import { apiUrl } from "@/env";

// Import interfaces for type safety
import {
  IErrorEvent,
  IFileUpdate,
  IImageItem,
  IPrinter,
  IPrinterCreate,
  IPrinterUpdate,
  IPrintJob,
  IPrintJobResponse,
  IPrintJobUpdate,
  IStripePricing,
  IStripePricingCreateAdmin,
  IStripePricingUpdateAdmin,
  IStripeProductCreateAdmin,
  IStripeProductUpdateAdmin,
  IStripeSubscriptionUpdateAdmin,
  IUserProfile,
  IUserProfileCreate,
  IUserProfileCreateAdmin,
  IUserProfileUpdate,
} from "./interfaces";

/**
 * Generate authorization headers for API requests.
 * @param token - JWT token for authentication.
 * @returns Axios request configuration with authorization headers.
 */
function authHeaders(token: string): AxiosRequestConfig {
  return {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
}

/**
 * Generate authorization headers with 'multipart/form-data' content type.
 * @param token - JWT token for authentication.
 * @returns Axios request configuration with authorization and content type headers.
 */
function authHeadersFormData(token: string): AxiosRequestConfig {
  const config = authHeaders(token);
  // Ensure headers is defined
  config.headers = config.headers || {};
  config.headers["Content-Type"] = "multipart/form-data";
  return config;
}

/**
 * Generate authorization headers with custom response type.
 * @param token - JWT token for authentication.
 * @param responseType - Expected response type (e.g., 'json', 'blob').
 * @returns Axios request configuration with authorization headers and response type.
 */
function authHeadersResponseType(
  token: string,
  responseType: ResponseType, // Explicitly type as ResponseType
): AxiosRequestConfig {
  const config = authHeaders(token);
  config.responseType = responseType;
  return config;
}

export const api = {
  // #region User Management
  async logInGetToken(username: string, password: string) {
    const params = new URLSearchParams();
    params.append("username", username);
    params.append("password", password);
    return axios.post(`${apiUrl}/api/v1/login/access-token`, params);
  },
  async getMe(token: string) {
    return axios.get<IUserProfile>(`${apiUrl}/api/v1/users/me`, authHeaders(token));
  },
  async updateMe(token: string, data: IUserProfileUpdate) {
    return axios.put<IUserProfile>(
      `${apiUrl}/api/v1/users/me`,
      data,
      authHeaders(token),
    );
  },
  async getUsers(token: string) {
    return axios.get<IUserProfile[]>(`${apiUrl}/api/v1/users/`, authHeaders(token));
  },
  async updateUser(token: string, userId: number, data: IUserProfileUpdate) {
    return axios.put(`${apiUrl}/api/v1/users/${userId}`, data, authHeaders(token));
  },
  async createUser(token: string, data: IUserProfileCreateAdmin) {
    return axios.post(`${apiUrl}/api/v1/users/`, data, authHeaders(token));
  },
  async createNewUser(data: IUserProfileCreate) {
    return axios.post(`${apiUrl}/api/v1/users/open`, data);
  },
  async deleteUser(token: string, id: number) {
    return axios.delete(`${apiUrl}/api/v1/users/${id}`, authHeaders(token));
  },
  async passwordRecovery(email: string) {
    return axios.post(`${apiUrl}/api/v1/password-recovery/${email}`);
  },
  async resetPassword(password: string, token: string) {
    return axios.post(`${apiUrl}/api/v1/reset-password/`, {
      new_password: password,
      token,
    });
  },
  // #endregion

  // #region Printer Management
  async getPrinters(token: string) {
    return axios.get<IPrinter[]>(`${apiUrl}/api/v1/printers/`, authHeaders(token));
  },
  async getPrinter(token: string, id: number) {
    return axios.get<IPrinter>(`${apiUrl}/api/v1/printers/${id}`, authHeaders(token));
  },
  async getAllPrinters(token: string) {
    return axios.get<IPrinter[]>(`${apiUrl}/api/v1/printers/all`, authHeaders(token));
  },
  async updatePrinter(token: string, id: number, data: IPrinterUpdate) {
    return axios.put(`${apiUrl}/api/v1/printers/${id}`, data, authHeaders(token));
  },
  async createPrinter(token: string, data: IPrinterCreate) {
    return axios.post(`${apiUrl}/api/v1/printers/`, data, authHeaders(token));
  },
  async deletePrinter(token: string, id: number) {
    return axios.delete(`${apiUrl}/api/v1/printers/${id}`, authHeaders(token));
  },
  // #endregion

  // #region Print Job Management
  async getPrintJobs(token: string) {
    return axios.get<IPrintJob[]>(`${apiUrl}/api/v1/print-jobs/`, authHeaders(token));
  },
  async getAllPrintJobs(
    token: string,
    limit?: number,
    sort_by?: string,
    sort_desc?: boolean,
  ) {
    const params = new URLSearchParams();
    if (limit) {
      params.append("limit", String(limit));
    }
    if (sort_by) {
      params.append("sort_by", String(sort_by));
    }
    if (sort_desc === true || sort_desc === false) {
      params.append("sort_desc", String(sort_desc));
    }
    return axios.get<IPrintJobResponse[]>(`${apiUrl}/api/v1/print-jobs/all`, {
      ...authHeaders(token),
      params: params,
    });
  },
  async getPrintJobCount(token: string) {
    return axios.get(`${apiUrl}/api/v1/print-jobs/count/`, authHeaders(token));
  },
  async getPrintJobSuccessRate(token: string) {
    return axios.get(`${apiUrl}/api/v1/print-jobs/success-rate`, authHeaders(token));
  },
  async getPrintJobGcode(token: string, id: number) {
    return axios.get(`${apiUrl}/api/v1/print-jobs/${id}/gcode`, authHeaders(token));
  },
  async getPrintJobCSV(token: string, id: number) {
    return axios.get(`${apiUrl}/api/v1/print-jobs/${id}/csv`, authHeaders(token));
  },
  async getPrintJobData(token: string, id: number) {
    return axios.get(
      `${apiUrl}/api/v1/print-jobs/remote/download/print_job/${id}`,
      authHeadersResponseType(token, "blob"),
    );
  },
  async updatePrintJob(token: string, id: number, data: IPrintJobUpdate) {
    return axios.put(`${apiUrl}/api/v1/print-jobs/${id}`, data, authHeaders(token));
  },
  async postPrintJobZip(token: string, data: FormData) {
    return axios.post(
      `${apiUrl}/api/v1/print-jobs/zip`,
      data,
      authHeadersFormData(token),
    );
  },
  async postAnalysePrintJob(token: string, data: any) {
    // Remove start_time and end_time if they exist in the data
    delete data.start_time;
    delete data.end_time;
    return axios.post(`${apiUrl}/api/v1/print-jobs/analyse`, data, authHeaders(token));
  },
  async deletePrintJob(token: string, id: number) {
    return axios.delete(`${apiUrl}/api/v1/print-jobs/${id}`, authHeaders(token));
  },
  // #endregion

  // #region Caxton Heatmap
  async postCaxtonHeatmap(token: string, data: any, rerun: boolean = false) {
    // Build the URL with the optional rerun parameter
    const url = rerun
      ? `${apiUrl}/api/v1/print-jobs/caxton-heatmap?rerun=true`
      : `${apiUrl}/api/v1/print-jobs/caxton-heatmap`;
    return axios.post(url, data, authHeaders(token));
  },
  // #endregion

  // #region Error Event Management
  async getErrorEvents(token: string) {
    return axios.get<IErrorEvent[]>(
      `${apiUrl}/api/v1/eventlog/error-events`,
      authHeaders(token),
    );
  },
  // #endregion

  // #region Image Item Management
  async getPrintJobImageItems(token: string, id: number) {
    return axios.get<IImageItem[]>(
      `${apiUrl}/api/v1/images/print/${id}`,
      authHeaders(token),
    );
  },
  async adminGetPrintJobImageItems(token: string, id: number, raw: boolean = false) {
    const url = raw
      ? `${apiUrl}/api/v1/images/print/${id}/admin?raw=true`
      : `${apiUrl}/api/v1/images/print/${id}/admin`;
    return axios.get<IImageItem[]>(url, authHeaders(token));
  },
  async getImageItemCount(token: string) {
    return axios.get(`${apiUrl}/api/v1/images/count`, authHeaders(token));
  },
  async getImageItemCountByDateRange(
    token: string,
    startDate: string,
    endDate: string,
  ) {
    return axios.get(
      `${apiUrl}/api/v1/images/count/date-range?start_date=${startDate}&end_date=${endDate}`,
      authHeaders(token),
    );
  },
  async getPrintJobImageItemCount(token: string, id: number) {
    return axios.get(`${apiUrl}/api/v1/images/print/${id}/count`, authHeaders(token));
  },
  async getPrintJobMiddleImageItem(token: string, id: number) {
    return axios.get(`${apiUrl}/api/v1/images/print/${id}/middle`, authHeaders(token));
  },
  async getPrintJobImageItem(
    token: string,
    print_job_id: number,
    image_item_count: number,
  ) {
    return axios.get(
      `${apiUrl}/api/v1/images/print/${print_job_id}/image/${image_item_count}`,
      authHeaders(token),
    );
  },
  // #endregion

  // #region File Management
  async getFiles(token: string, id: number) {
    return axios.get(`${apiUrl}/api/v1/files/by-parent/${id}`, authHeaders(token));
  },
  async getAdminFiles(token: string) {
    return axios.get(`${apiUrl}/api/v1/files/all`, authHeaders(token));
  },
  async getFile(token: string, id: number) {
    return axios.get(`${apiUrl}/api/v1/files/${id}`, authHeaders(token));
  },
  async createFile(token: string, data: any) {
    return axios.post(`${apiUrl}/api/v1/files/`, data, authHeaders(token));
  },
  async updateFile(token: string, id: number, data: IFileUpdate) {
    return axios.put(`${apiUrl}/api/v1/files/${id}`, data, authHeaders(token));
  },
  async deleteFile(token: string, id: number) {
    return axios.delete(`${apiUrl}/api/v1/files/${id}`, authHeaders(token));
  },
  async deleteAllFiles(token: string) {
    return axios.delete(`${apiUrl}/api/v1/files/`, authHeaders(token));
  },
  async makeDirectory(token: string, data: any) {
    return axios.post(`${apiUrl}/api/v1/files/mkdir`, data, authHeaders(token));
  },
  // #endregion

  // #region Notifications Management
  async getNotifications(token: string) {
    return axios.get(`${apiUrl}/api/v1/notifications/`, authHeaders(token));
  },
  async postViewedNotifications(token: string, data: any) {
    return axios.post(
      `${apiUrl}/api/v1/notifications/viewed`,
      data,
      authHeaders(token),
    );
  },
  async postDismissedNotifications(token: string, data: any) {
    return axios.post(
      `${apiUrl}/api/v1/notifications/dismiss`,
      data,
      authHeaders(token),
    );
  },
  // #endregion

  // #region Announcements Management
  async getAnnouncements(token: string) {
    return axios.get(`${apiUrl}/api/v1/announcements/`, authHeaders(token));
  },
  async getAdminAnnouncements(token: string) {
    return axios.get(`${apiUrl}/api/v1/announcements/all`, authHeaders(token));
  },
  async createAnnouncement(token: string, data: any) {
    return axios.post(`${apiUrl}/api/v1/announcements/`, data, authHeaders(token));
  },
  async updateAnnouncement(token: string, id: number, data: IStripeProductUpdateAdmin) {
    return axios.put(`${apiUrl}/api/v1/announcements/${id}`, data, authHeaders(token));
  },
  async deleteAnnouncement(token: string, id: number) {
    return axios.delete(`${apiUrl}/api/v1/announcements/${id}`, authHeaders(token));
  },
  // #endregion

  // #region Products Management
  async getProducts(token: string) {
    return axios.get(`${apiUrl}/api/v1/products/`, authHeaders(token));
  },
  async getAdminProducts(token: string) {
    return axios.get(`${apiUrl}/api/v1/products/all`, authHeaders(token));
  },
  async createProduct(token: string, data: IStripeProductCreateAdmin) {
    return axios.post(`${apiUrl}/api/v1/products/`, data, authHeaders(token));
  },
  async updateProduct(token: string, id: number, data: IStripeProductUpdateAdmin) {
    return axios.put(`${apiUrl}/api/v1/products/${id}`, data, authHeaders(token));
  },
  async deleteProduct(token: string, id: number) {
    return axios.delete(`${apiUrl}/api/v1/products/${id}`, authHeaders(token));
  },
  // #endregion

  // #region Pricings Management
  async getPricings(token: string) {
    return axios.get(`${apiUrl}/api/v1/pricings/`, authHeaders(token));
  },
  async getAdminPricings(token: string) {
    return axios.get(`${apiUrl}/api/v1/pricings/all`, authHeaders(token));
  },
  async createPricing(token: string, data: IStripePricingCreateAdmin) {
    return axios.post(`${apiUrl}/api/v1/pricings/`, data, authHeaders(token));
  },
  async updatePricing(token: string, id: number, data: IStripePricingUpdateAdmin) {
    return axios.put(`${apiUrl}/api/v1/pricings/${id}`, data, authHeaders(token));
  },
  async deletePricing(token: string, id: number) {
    return axios.delete(`${apiUrl}/api/v1/pricings/${id}`, authHeaders(token));
  },
  async createCheckoutSession(token: string, data: IStripePricing) {
    // Clone the data object and add success_url and cancel_url properties
    const requestData = {
      ...data,
      success_url: `${window.location.origin}/main/printers/view`,
      cancel_url: `${window.location.origin}/main/products/view`,
    };
    return axios.post(
      `${apiUrl}/api/v1/pricings/create-checkout-session`,
      requestData,
      authHeaders(token),
    );
  },
  async createCustomerPortalSession(token: string, data: any) {
    return axios.post(
      `${apiUrl}/api/v1/subscriptions/create-customer-portal-session`,
      data,
      authHeaders(token),
    );
  },
  // #endregion

  // #region Subscriptions Management
  async getUserSubscriptions(token: string) {
    // Get subscriptions for the authenticated user (active and inactive)
    return axios.get(`${apiUrl}/api/v1/subscriptions/`, authHeaders(token));
  },
  async getAllSubscriptions(token: string) {
    // Get all subscriptions for all users (requires admin permissions)
    return axios.get(`${apiUrl}/api/v1/subscriptions/all`, authHeaders(token));
  },
  async updateSubscription(
    token: string,
    id: number,
    data: IStripeSubscriptionUpdateAdmin,
  ) {
    return axios.put(`${apiUrl}/api/v1/subscriptions/${id}`, data, authHeaders(token));
  },
  async deleteSubscription(token: string, id: number) {
    return axios.delete(`${apiUrl}/api/v1/subscriptions/${id}`, authHeaders(token));
  },
  // #endregion

  // #region Gcode Management
  async postGcodeUpload(token: string, data: FormData) {
    return axios.post(
      `${apiUrl}/api/v1/print-jobs/client/gcode/uploadfile`,
      data,
      authHeadersFormData(token),
    );
  },
  // #endregion

  // #region Macros Management
  async getMacros(token: string) {
    return axios.get(`${apiUrl}/api/v1/macros/`, authHeaders(token));
  },
  async getMacro(token: string, id: number) {
    return axios.get(`${apiUrl}/api/v1/macros/${id}`, authHeaders(token));
  },
  async getAllMacros(token: string) {
    return axios.get(`${apiUrl}/api/v1/macros/all`, authHeaders(token));
  },
  async getPrinterMacros(token: string) {
    return axios.get(`${apiUrl}/api/v1/macros/printer-macros/`, authHeaders(token));
  },
  async createMacro(token: string, data: any) {
    return axios.post(`${apiUrl}/api/v1/macros/`, data, authHeaders(token));
  },
  async createPrinterMacro(token: string, data: any) {
    return axios.post(
      `${apiUrl}/api/v1/macros/printer-macro/`,
      data,
      authHeaders(token),
    );
  },
  async updateMacro(token: string, id: number, data: any) {
    return axios.put(`${apiUrl}/api/v1/macros/${id}`, data, authHeaders(token));
  },
  async deleteMacro(token: string, id: number) {
    return axios.delete(`${apiUrl}/api/v1/macros/${id}`, authHeaders(token));
  },
  async deletePrinterMacro(token: string, id: number) {
    return axios.delete(
      `${apiUrl}/api/v1/macros/printer-macro/${id}`,
      authHeaders(token),
    );
  },
  // #endregion

  // #region Materials Management
  async getMaterials(token: string) {
    return axios.get(`${apiUrl}/api/v1/materials/`, authHeaders(token));
  },
  async createMaterial(token: string, data: any) {
    return axios.post(`${apiUrl}/api/v1/materials/`, data, authHeaders(token));
  },
  async updateMaterial(token: string, id: number, data: any) {
    return axios.put(`${apiUrl}/api/v1/materials/${id}`, data, authHeaders(token));
  },
  async deleteMaterial(token: string, id: number) {
    return axios.delete(`${apiUrl}/api/v1/materials/${id}`, authHeaders(token));
  },
  // #endregion

  // #region Tag Management
  async getTags(token: string) {
    return axios.get(`${apiUrl}/api/v1/tags/`, authHeaders(token));
  },
  async getTag(token: string, id: number) {
    return axios.get(`${apiUrl}/api/v1/tags/${id}`, authHeaders(token));
  },
  async createTag(token: string, data: any) {
    return axios.post(`${apiUrl}/api/v1/tags/`, data, authHeaders(token));
  },
  async updateTag(token: string, id: number, data: any) {
    return axios.put(`${apiUrl}/api/v1/tags/${id}`, data, authHeaders(token));
  },
  async deleteTag(token: string, id: number) {
    return axios.delete(`${apiUrl}/api/v1/tags/${id}`, authHeaders(token));
  },

  async createPrinterTag(token: string, data: any) {
    return axios.post(`${apiUrl}/api/v1/printer-tags/`, data, authHeaders(token));
  },
  async deletePrinterTag(token: string, id: number) {
    return axios.delete(`${apiUrl}/api/v1/printer-tags/${id}`, authHeaders(token));
  },
  // #endregion

  // #region Miscellaneous
  async getTasks(token: string) {
    return axios.get(`${apiUrl}/api/v1/utils/tasks`, authHeaders(token));
  },
  async getTaskIds(token: string) {
    return axios.get(
      `${apiUrl}/api/v1/utils/tasks/user/active-ids`,
      authHeaders(token),
    );
  },
  async getTaskStatus(token: string, task_id: string) {
    return axios.get(
      `${apiUrl}/api/v1/utils/tasks/status/${task_id}`,
      authHeaders(token),
    );
  },
  async getWorkerTaskStatus(token: string, task_id: string) {
    return axios.get(
      `${apiUrl}/api/v1/utils/tasks/worker/${task_id}`,
      authHeaders(token),
    );
  },
  async getPluginVersions(token: string) {
    return axios.get(`${apiUrl}/api/v1/utils/plugin-version`, authHeaders(token));
  },
  // #endregion
};
