import {
  IAnnouncement,
  IImageItem,
  IPrinter,
  IPrintJobResponse,
  IStripePricing,
  IStripeProduct,
  IStripeSubscription,
  IUserProfile,
} from "@/interfaces";
import { AdminState } from "./state";
import { getStoreAccessors } from "typesafe-vuex";
import { State } from "../state";

export const mutations = {
  adminSetUsers(state: AdminState, payload: IUserProfile[]) {
    state.adminUsers = payload;
  },
  adminSetUser(state: AdminState, payload: IUserProfile) {
    const users = state.adminUsers.filter(
      (user: IUserProfile) => user.id !== payload.id,
    );
    users.push(payload);
    state.adminUsers = users;
  },
  adminDeleteUser(state: AdminState, payload: IUserProfile) {
    const userIndex = state.adminUsers.findIndex(
      (user: IUserProfile) => user.id === payload.id,
    );
    if (userIndex !== -1) {
      state.adminUsers.splice(userIndex, 1);
    }
  },
  adminSetPrinters(state: AdminState, payload: IPrinter[]) {
    state.adminPrinters = payload;
  },
  adminSetPrintJobs(state: AdminState, payload: IPrintJobResponse[]) {
    state.adminPrintJobs = payload;
  },
  adminSetImageItems(state: AdminState, payload: IImageItem[]) {
    state.adminImageItems = payload;
  },
  adminSetImageItemCount(state: AdminState, payload: number) {
    state.adminImageItemCount = payload;
  },
  adminSetProducts(state: AdminState, payload: IStripeProduct[]) {
    state.adminStripeProducts = payload;
  },
  adminSetProduct(state: AdminState, payload: IStripeProduct) {
    const products = state.adminStripeProducts.filter(
      (product: IStripeProduct) => product.id !== payload.id,
    );
    products.push(payload);
    state.adminStripeProducts = products;
  },
  adminDeleteProduct(state: AdminState, payload: IStripeProduct) {
    const productIndex = state.adminStripeProducts.findIndex(
      (product: IStripeProduct) => product.id === payload.id,
    );
    if (productIndex !== -1) {
      state.adminStripeProducts.splice(productIndex, 1);
    }
  },
  adminSetPricings(state: AdminState, payload: IStripePricing[]) {
    state.adminStripePricings = payload;
  },
  adminSetPricing(state: AdminState, payload: IStripePricing) {
    const pricings = state.adminStripePricings.filter(
      (pricing: IStripePricing) => pricing.id !== payload.id,
    );
    pricings.push(payload);
    state.adminStripePricings = pricings;
  },
  adminDeletePricing(state: AdminState, payload: IStripePricing) {
    const pricingIndex = state.adminStripePricings.findIndex(
      (pricing: IStripePricing) => pricing.id === payload.id,
    );
    if (pricingIndex !== -1) {
      state.adminStripePricings.splice(pricingIndex, 1);
    }
  },
  adminSetSubscriptions(state: AdminState, payload: IStripeSubscription[]) {
    state.adminStripeSubscriptions = payload;
  },
  adminSetSubscription(state: AdminState, payload: IStripeSubscription) {
    const subscriptions = state.adminStripeSubscriptions.filter(
      (subscription: IStripeSubscription) => subscription.id !== payload.id,
    );
    subscriptions.push(payload);
    state.adminStripeSubscriptions = subscriptions;
  },
  adminDeleteSubscription(state: AdminState, payload: IStripeSubscription) {
    const subscriptionIndex = state.adminStripeSubscriptions.findIndex(
      (subscription: IStripeSubscription) => subscription.id === payload.id,
    );
    if (subscriptionIndex !== -1) {
      state.adminStripeSubscriptions.splice(subscriptionIndex, 1);
    }
  },
  adminSetAnnouncements(state: AdminState, payload: IAnnouncement[]) {
    state.adminAnnouncements = payload;
  },
  adminSetAnnouncement(state: AdminState, payload: IAnnouncement) {
    const announcements = state.adminAnnouncements.filter(
      (announcement: IAnnouncement) => announcement.id !== payload.id,
    );
    announcements.push(payload);
    state.adminAnnouncements = announcements;
  },
  adminDeleteAnnouncement(state: AdminState, payload: IAnnouncement) {
    const announcementIndex = state.adminAnnouncements.findIndex(
      (announcement: IAnnouncement) => announcement.id === payload.id,
    );
    if (announcementIndex !== -1) {
      state.adminAnnouncements.splice(announcementIndex, 1);
    }
  },
};

const { commit } = getStoreAccessors<AdminState, State>("");

export const commitAdminSetUser = commit(mutations.adminSetUser);
export const commitAdminSetUsers = commit(mutations.adminSetUsers);
export const commitAdminDeleteUser = commit(mutations.adminDeleteUser);
export const commitAdminSetPrinters = commit(mutations.adminSetPrinters);
export const commitAdminSetPrintJobs = commit(mutations.adminSetPrintJobs);
export const commitAdminSetImageItems = commit(mutations.adminSetImageItems);
export const commitAdminSetImageItemCount = commit(mutations.adminSetImageItemCount);
export const commitAdminSetProduct = commit(mutations.adminSetProduct);
export const commitAdminSetProducts = commit(mutations.adminSetProducts);
export const commitAdminDeleteProduct = commit(mutations.adminDeleteProduct);
export const commitAdminSetPricings = commit(mutations.adminSetPricings);
export const commitAdminSetPricing = commit(mutations.adminSetPricing);
export const commitAdminDeletePricing = commit(mutations.adminDeletePricing);
export const commitAdminSetSubscriptions = commit(mutations.adminSetSubscriptions);
export const commitAdminSetSubscription = commit(mutations.adminSetSubscription);
export const commitAdminDeleteSubscription = commit(mutations.adminDeleteSubscription);
export const commitAdminSetAnnouncements = commit(mutations.adminSetAnnouncements);
export const commitAdminSetAnnouncement = commit(mutations.adminSetAnnouncement);
export const commitAdminDeleteAnnouncement = commit(mutations.adminDeleteAnnouncement);
