<template>
  <div>
    <v-snackbar v-model="show" :color="currentAlertColor">
      <v-progress-circular
        v-show="showProgress"
        class="ma-2"
        indeterminate
      ></v-progress-circular
      >{{ currentAlertContent }}
      <template #action="{ attrs }">
        <v-btn v-bind="attrs" text @click.native="close">Close</v-btn>
      </template>
    </v-snackbar>
  </div>
</template>
<script lang="ts">
import { Vue, Component, Watch } from "vue-property-decorator";
import { IAppAlert } from "@/interfaces";
import { commitRemoveAlert } from "@/store/main/mutations";
import { readFirstAlert } from "@/store/main/getters";
import { dispatchRemoveAlert } from "@/store/main/actions";

@Component
export default class AlertsManager extends Vue {
  public show = false;
  public text = "";
  public showProgress = false;
  public currentAlert: IAppAlert | false = false;

  public async hide() {
    this.show = false;
    await new Promise<void>((resolve, _) => setTimeout(() => resolve(), 500));
  }

  public async close() {
    await this.hide();
    await this.removeCurrentAlert();
  }

  public async removeCurrentAlert() {
    if (this.currentAlert) {
      commitRemoveAlert(this.$store, this.currentAlert);
    }
  }

  public get firstAlert() {
    return readFirstAlert(this.$store);
  }

  public async setAlert(alert: IAppAlert | false) {
    if (this.show) {
      await this.hide();
    }
    if (alert) {
      this.currentAlert = alert;
      this.showProgress = alert.showProgress || false;
      this.show = true;
    } else {
      this.currentAlert = false;
    }
  }

  @Watch("firstAlert")
  public async onAlertChange(newAlert: IAppAlert | false) {
    if (newAlert !== this.currentAlert) {
      await this.setAlert(newAlert);
      if (newAlert) {
        dispatchRemoveAlert(this.$store, {
          alert: newAlert,
          timeout: 6500,
        });
      }
    }
  }

  public get currentAlertContent() {
    return (this.currentAlert && this.currentAlert.content) || "";
  }

  public get currentAlertColor() {
    return (this.currentAlert && this.currentAlert.color) || "info";
  }
}
</script>
