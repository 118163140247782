import { api } from "@/api";
import {
  IPrintJobUpdate,
  IPrinterCreate,
  IPrinterUpdate,
  IUserProfileCreate,
  IMacroCreate,
  IMacroUpdate,
  IPrinterMacroCreate,
  IFileUpdate,
  IAppAlert,
  ITagCreate,
  ITagUpdate,
  IMaterialCreate,
} from "@/interfaces";
import router from "@/router";
import { getLocalToken, removeLocalToken, saveLocalToken } from "@/utils";
import axios from "axios";
import { getStoreAccessors } from "typesafe-vuex";
import { ActionContext } from "vuex";
import { State } from "../state";
import {
  commitAddAlert,
  commitRemoveAlert,
  commitSetLoggedIn,
  commitSetLogInError,
  commitSetSignUpError,
  commitSetToken,
  commitSetUserProfile,
  commitSetPrintJobs,
  commitSetPrintJob,
  commitSetImageItems,
  commitSetImageItemCount,
  commitSetFiles,
  commitSetPrinters,
  commitSetPrinter,
  commitSetPrinterSockets,
  commitSetPrinterSocket,
  commitSetUserSocket,
  commitSetNotifications,
  commitSetProducts,
  commitSetPricings,
  commitSetUserSubscriptions,
  commitSetMacros,
  commitSetMacro,
  commitSetMaterials,
  commitSetErrorEvents,
  commitSetFile,
  commitSetOriginalURL,
  commitSetAnnouncements,
  commitSetPluginVersions,
  commitSetTask,
  commitSetTags,
  commitSetTag,
  commitDeleteTag,
  commitSetMaterial,
} from "./mutations";
import { MainState } from "./state";

type MainContext = ActionContext<MainState, State>;

export const actions = {
  async actionCreateNewUser(context: MainContext, payload: IUserProfileCreate) {
    try {
      const response = await api.createNewUser(payload);
      if (response.data && payload.email && payload.password) {
        // get error message from response
        await dispatchLogIn(context, {
          username: payload.email,
          password: payload.password,
        });
      }
      commitSetSignUpError(context, false);
    } catch (error) {
      // TODO: propagate the message to the frontend.
      commitSetSignUpError(context, true);
      await dispatchCheckApiError(context, error);
    }
  },
  async actionLogIn(
    context: MainContext,
    payload: { username: string; password: string; originalURL?: string },
  ) {
    try {
      const response = await api.logInGetToken(payload.username, payload.password);
      const token = response.data.access_token;
      if (token) {
        sessionStorage.clear();
        saveLocalToken(token);
        commitSetToken(context, token);
        commitSetLoggedIn(context, true);
        commitSetLogInError(context, false);
        await dispatchGetUserProfile(context);
        await dispatchRouteLoggedIn(context, payload.originalURL);
        commitAddAlert(context, { content: "Logged in", color: "success" });
      } else {
        await dispatchLogOut(context);
      }
    } catch (err) {
      commitSetLogInError(context, true);
      await dispatchLogOut(context);
    }
  },
  async actionGetUserProfile(context: MainContext) {
    try {
      const response = await api.getMe(context.state.token);
      if (response.data) {
        commitSetUserProfile(context, response.data);
      }
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },
  async actionGetUserWebsocket(context: MainContext) {
    try {
      const response = await api.getMe(context.state.token);
      if (response.data) {
        await commitSetUserSocket(context.state, response.data["auth_key"]);
      }
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },
  async actionUpdateUserProfile(context: MainContext, payload) {
    try {
      const loadingAlert = { content: "Saving", showProgress: true };
      commitAddAlert(context, loadingAlert);
      const response = (
        await Promise.all([
          api.updateMe(context.state.token, payload),
          new Promise<void>((resolve, _) => setTimeout(() => resolve(), 500)),
        ])
      )[0];
      commitSetUserProfile(context, response.data);
      commitRemoveAlert(context, loadingAlert);
      commitAddAlert(context, {
        content: "Profile successfully updated",
        color: "success",
      });
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },
  async actionCheckLoggedIn(context: MainContext) {
    if (!context.state.isLoggedIn) {
      let token = context.state.token;
      if (!token) {
        const localToken = getLocalToken();
        if (localToken) {
          commitSetToken(context, localToken);
          token = localToken;
        }
      }
      if (token) {
        try {
          const response = await api.getMe(token);
          commitSetLoggedIn(context, true);
          commitSetUserProfile(context, response.data);
        } catch (error) {
          await dispatchRemoveLogIn(context);
        }
      } else {
        await dispatchRemoveLogIn(context);
      }
    }
  },
  async actionRemoveLogIn(context: MainContext) {
    removeLocalToken();
    commitSetToken(context, "");
    commitSetLoggedIn(context, false);
    try {
      commitSetPrinters(context, []);
    } catch (error) {
      console.error(error);
    }
  },
  async actionLogOut(context: MainContext) {
    await dispatchRemoveLogIn(context);
    await dispatchRouteLogOut(context);
  },
  async actionUserLogOut(context: MainContext) {
    await dispatchLogOut(context);
    commitAddAlert(context, { content: "Logged out", color: "success" });
  },
  actionRouteLogOut() {
    if (router.currentRoute.path !== "/login") {
      router.push("/login");
    }
  },
  async actionCheckApiError(context: MainContext, payload: unknown) {
    if (axios.isAxiosError(payload)) {
      if (payload.response?.status === 401) {
        await dispatchLogOut(context);
      }
    }
  },
  actionRouteLoggedIn(context: MainContext, originalURL: string | undefined) {
    if (originalURL && originalURL !== "") {
      router.push(originalURL).catch(() => {});
      commitSetOriginalURL(context, "");
      return;
    }
    if (
      router.currentRoute.path === "/login" ||
      router.currentRoute.path === "/" ||
      router.currentRoute.path === "/signup"
    ) {
      router.push("/main/printers/view");
    }
  },
  async removeAlert(
    context: MainContext,
    payload: { alert: IAppAlert; timeout: number },
  ) {
    return new Promise((resolve, _) => {
      setTimeout(() => {
        commitRemoveAlert(context, payload.alert);
        resolve(true);
      }, payload.timeout);
    });
  },
  async passwordRecovery(context: MainContext, payload: { username: string }) {
    const loadingAlert = {
      content: "Sending password recovery email",
      showProgress: true,
    };
    try {
      commitAddAlert(context, loadingAlert);
      await Promise.all([
        api.passwordRecovery(payload.username),
        new Promise<void>((resolve, _) => setTimeout(() => resolve(), 500)),
      ]);
      commitRemoveAlert(context, loadingAlert);
      commitAddAlert(context, {
        content: "Password recovery email sent",
        color: "success",
      });
      await dispatchLogOut(context);
    } catch (error) {
      commitRemoveAlert(context, loadingAlert);
      commitAddAlert(context, { color: "error", content: "Incorrect username" });
    }
  },
  async resetPassword(
    context: MainContext,
    payload: { password: string; token: string },
  ) {
    const loadingAlert = { content: "Resetting password", showProgress: true };
    try {
      commitAddAlert(context, loadingAlert);
      await Promise.all([
        api.resetPassword(payload.password, payload.token),
        new Promise<void>((resolve, _) => setTimeout(() => resolve(), 500)),
      ]);
      commitRemoveAlert(context, loadingAlert);
      commitAddAlert(context, {
        content: "Password successfully reset",
        color: "success",
      });
      await dispatchLogOut(context);
    } catch (error) {
      commitRemoveAlert(context, loadingAlert);
      commitAddAlert(context, {
        color: "error",
        content: "Error resetting password",
      });
    }
  },
  async actionGetPrintJobs(context: MainContext) {
    try {
      const response = await api.getPrintJobs(context.state.token);
      if (response.data) {
        commitSetPrintJobs(context, response.data);
      }
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },
  async actionGetPrintJobSuccessRate(context: MainContext) {
    try {
      const response = await api.getPrintJobSuccessRate(context.state.token);
      return response.data; // TODO: commitSetPrintJobs the success rate?
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },
  async actionUpdatePrintJob(
    context: MainContext,
    payload: { id: number; printJob: IPrintJobUpdate },
  ) {
    try {
      const response = (
        await Promise.all([
          api.updatePrintJob(context.state.token, payload.id, payload.printJob),
          new Promise<void>((resolve, _) => setTimeout(() => resolve(), 500)),
        ])
      )[0];
      commitSetPrintJob(context, response.data);
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },
  async actionPostHeatmap(
    context: MainContext,
    payload: { data: any; rerun?: boolean },
  ) {
    try {
      const response = await api.postCaxtonHeatmap(
        context.state.token,
        payload.data,
        payload.rerun,
      );
      return response.data;
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },
  async actionGetErrorEvents(context: MainContext) {
    try {
      const response = await api.getErrorEvents(context.state.token);
      if (response.data) {
        commitSetErrorEvents(context, response.data);
        return response.data;
      }
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },
  async actionGetImageItems(context: MainContext, payload) {
    try {
      const response = await api.getPrintJobImageItems(context.state.token, payload);
      if (response.data) {
        commitSetImageItems(context, response.data);
      }
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },
  async actionGetImageItemCount(context: MainContext) {
    try {
      const response = await api.getImageItemCount(context.state.token);
      if (response.data !== undefined) {
        commitSetImageItemCount(context, response.data);
      }
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },
  async actionGetImageItemCountByDateRange(
    context: MainContext,
    payload: { startDate: string; endDate: string },
  ) {
    try {
      const response = await api.getImageItemCountByDateRange(
        context.state.token,
        payload.startDate, // note: this is a str in the format YYYY-MM-DD
        payload.endDate, // note: this is a str in the format YYYY-MM-DD
      );
      if (response.data !== undefined) {
        commitSetImageItemCount(context, response.data);
      }
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },
  async actionGetPrintJobImageItemCount(context: MainContext, payload) {
    try {
      const response = await api.getPrintJobImageItemCount(
        context.state.token,
        payload,
      );
      if (response.data !== undefined) {
        return response.data;
      }
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },
  async actionPostPrintJobZip(context: MainContext, payload) {
    try {
      const response = await api.postPrintJobZip(context.state.token, payload);
      if (response.data) {
        return response.data;
      }
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },
  async actionPostGcodeUpload(context: MainContext, payload) {
    try {
      const loadingAlert = { content: "Uploading gcode...", showProgress: true };
      commitAddAlert(context, loadingAlert);
      const response = await api.postGcodeUpload(context.state.token, payload);
      if (response.data) {
        return response.data;
      }
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },
  async actionGetPrintJobMiddleImageItem(context: MainContext, id: number) {
    try {
      const response = await api
        .getPrintJobMiddleImageItem(context.state.token, id)
        .then((response) => {
          return response;
        });
      if (response.data) {
        return response.data;
      }
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },
  async actionGetPrintJobImageItem(
    context: MainContext,
    payload: { print_job_id: number; image_item_count: number },
  ) {
    try {
      const response = await api
        .getPrintJobImageItem(
          context.state.token,
          payload.print_job_id,
          payload.image_item_count,
        )
        .then((response) => {
          return response;
        });
      if (response.data) {
        return response.data;
      }
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },
  async actionGetFiles(context: MainContext, payload) {
    try {
      const response = await api.getFiles(context.state.token, payload);
      if (response.data) {
        commitSetFiles(context, response.data);
      }
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },
  async actionPostFile(context: MainContext, payload) {
    const loadingAlert = { content: "Uploading file...", showProgress: true };
    try {
      commitAddAlert(context, loadingAlert);
      const response = await api.createFile(context.state.token, payload);
      if (response.data) {
        commitRemoveAlert(context, loadingAlert);
        return response.data;
      }
    } catch (error: any) {
      // get the response error message from the error
      commitRemoveAlert(context, loadingAlert);
      const msg: string = error.response.data.detail;
      commitAddAlert(context, { content: msg, color: "error" });
      await dispatchCheckApiError(context, error);
    }
  },
  async actionUpdateFile(
    context: MainContext,
    payload: { id: number; file: IFileUpdate },
  ) {
    try {
      const response = await api.updateFile(
        context.state.token,
        payload.id,
        payload.file,
      );
      if (response.data) {
        commitSetFile(context, response.data);
        return response.data;
      }
    } catch (error: any) {
      const msg: string = error.response.data.detail;
      commitAddAlert(context, { content: msg, color: "error" });
      await dispatchCheckApiError(context, error);
      await dispatchCheckApiError(context, error);
    }
  },

  async actionDeleteFile(context: MainContext, id: number) {
    try {
      const loadingAlert = { content: "Deleting...", showProgress: true };
      commitAddAlert(context, loadingAlert);
      const response = await api.deleteFile(context.state.token, id);
      if (response.data) {
        commitRemoveAlert(context, loadingAlert);
        return response.data;
      }
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },
  async actionDeleteAllFiles(context: MainContext) {
    try {
      const loadingAlert = { content: "Deleting...", showProgress: true };
      commitAddAlert(context, loadingAlert);
      const response = await api.deleteAllFiles(context.state.token);
      if (response.data) {
        commitRemoveAlert(context, loadingAlert);
        return response.data;
      }
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },
  async actionPostMakeDirectory(context: MainContext, payload) {
    try {
      const response = await api.makeDirectory(context.state.token, payload);
      if (response.data) {
        commitSetFile(context, response.data);
        return response.data;
      }
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },
  async actionGetPrintJobGcode(context: MainContext, id: number) {
    try {
      const response = await api
        .getPrintJobGcode(context.state.token, id)
        .then((response) => {
          return response;
        });
      if (response.data) {
        return response.data;
      }
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },
  async actionGetPrintJobCSV(context: MainContext, id: number) {
    try {
      const response = await api
        .getPrintJobCSV(context.state.token, id)
        .then((response) => {
          return response;
        });
      if (response.data) {
        return response.data;
      }
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },
  async actionGetPrintJobData(context: MainContext, id) {
    try {
      const response = await api
        .getPrintJobData(context.state.token, id)
        .then((response) => {
          return response;
        });
      if (response.data) {
        return response.data;
      }
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },
  async actionPostAnalysePrintJob(context: MainContext, payload) {
    try {
      const response = await api
        .postAnalysePrintJob(context.state.token, payload)
        .then((response) => {
          return response;
        });
      if (response.data) {
        return response.data;
      }
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },
  async actionDeletePrintJob(context: MainContext, id: number) {
    try {
      const loadingAlert = { content: "Deleting...", showProgress: true };
      commitAddAlert(context, loadingAlert);
      const response = await api.deletePrintJob(context.state.token, id);
      if (response.data) {
        commitRemoveAlert(context, loadingAlert);
        return response.data;
      }
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },
  async actionGetPrinters(context: MainContext) {
    try {
      const response = await api.getPrinters(context.state.token);
      if (response.data) {
        commitSetPrinters(context, response.data);
        await commitSetPrinterSockets(context.state);
      }
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },
  async actionGetPrinter(context: MainContext, id: number) {
    try {
      const response = await api.getPrinter(context.state.token, id);
      if (response.data) {
        commitSetPrinter(context, response.data);
      }
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },
  async actionUpdatePrinter(
    context: MainContext,
    payload: { id: number; printer: IPrinterUpdate },
  ) {
    try {
      const loadingAlert = { content: "Saving", showProgress: true };
      commitAddAlert(context, loadingAlert);
      const response = (
        await Promise.all([
          api.updatePrinter(context.state.token, payload.id, payload.printer),
          new Promise<void>((resolve, _) => setTimeout(() => resolve(), 500)),
        ])
      )[0];
      commitSetPrinter(context, response.data);
      commitRemoveAlert(context, loadingAlert);
      commitAddAlert(context, {
        content: "Printer successfully updated",
        color: "success",
      });
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },
  async actionCreatePrinter(context: MainContext, payload: IPrinterCreate) {
    try {
      const loadingAlert = { content: "Saving", showProgress: true };
      commitAddAlert(context, loadingAlert);
      const response = (
        await Promise.all([
          api.createPrinter(context.state.token, payload),
          new Promise<void>((resolve, _) => setTimeout(() => resolve(), 500)),
        ])
      )[0];
      commitSetPrinter(context, response.data);
      commitRemoveAlert(context, loadingAlert);
      commitAddAlert(context, {
        content: "Printer successfully created",
        color: "success",
      });
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },
  async actionDeletePrinter(context: MainContext, id: number) {
    try {
      const loadingAlert = { content: "Deleting...", showProgress: true };
      commitAddAlert(context, loadingAlert);
      const response = await api.deletePrinter(context.state.token, id);
      if (response.data) {
        commitRemoveAlert(context, loadingAlert);
        return response.data;
      }
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },
  async actionCreatePrinterTag(
    context: MainContext,
    payload: { printer_id: number; tag_id: number },
  ) {
    try {
      const response = await api.createPrinterTag(context.state.token, payload);
      if (response.data) {
        return response.data;
      }
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },
  async actionDeletePrinterTag(context: MainContext, id: number) {
    try {
      const response = await api.deletePrinterTag(context.state.token, id);
      if (response.data) {
        return response.data;
      }
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },
  async actionGetPrinterSocket(context: MainContext, payload) {
    try {
      await commitSetPrinterSocket(context.state, payload);
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },
  async actionGetNotifications(context: MainContext) {
    try {
      const response = await api.getNotifications(context.state.token);
      if (response.data) {
        commitSetNotifications(context, response.data);
      }
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },
  async actionMarkViewedNotifications(context: MainContext, payload) {
    try {
      await api.postViewedNotifications(context.state.token, payload);
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },
  async actionDismissNotifications(context: MainContext, payload) {
    try {
      await api.postDismissedNotifications(context.state.token, payload);
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },
  async actionGetAnnouncements(context: MainContext) {
    try {
      const response = await api.getAnnouncements(context.state.token);
      if (response.data) {
        commitSetAnnouncements(context, response.data);
      }
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },
  async actionGetProducts(context: MainContext) {
    try {
      const response = await api.getProducts(context.state.token);
      if (response) {
        commitSetProducts(context, response.data);
      }
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },
  async actionGetPricings(context: MainContext) {
    try {
      const response = await api.getPricings(context.state.token);
      if (response) {
        commitSetPricings(context, response.data);
      }
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },
  async actionGetUserSubscriptions(context: MainContext) {
    try {
      const response = await api.getUserSubscriptions(context.state.token);
      if (response) {
        commitSetUserSubscriptions(context, response.data);
      }
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },
  async actionCreateCheckoutSession(context: MainContext, payload) {
    try {
      const response = await api.createCheckoutSession(context.state.token, payload);
      if (response) {
        window.location.href = response.data["url"];
      }
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },
  async actionCreateCustomerPortalSession(context: MainContext, payload) {
    try {
      const response = await api.createCustomerPortalSession(
        context.state.token,
        payload,
      );
      if (response) {
        window.location.href = response.data["url"];
      }
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },
  // Macros!
  async actionGetMacros(context: MainContext) {
    // watch this
    try {
      const response = await api.getPrinterMacros(context.state.token);
      if (response.data) {
        commitSetMacros(context, response.data);
      }
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },
  async actionGetMacro(context: MainContext, id: number) {
    try {
      const response = await api.getMacro(context.state.token, id);
      if (response.data) {
        return response.data;
      }
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },
  async actionUpdateMacro(
    context: MainContext,
    payload: { id: number; macro: IMacroUpdate },
  ) {
    try {
      const loadingAlert = { content: "Saving", showProgress: true };
      commitAddAlert(context, loadingAlert);
      const response = (
        await Promise.all([
          api.updateMacro(context.state.token, payload.id, payload.macro),
          new Promise<void>((resolve, _) => setTimeout(() => resolve(), 500)),
        ])
      )[0];
      commitSetMacro(context, response.data);
      commitRemoveAlert(context, loadingAlert);
      commitAddAlert(context, {
        content: "Macro successfully updated",
        color: "success",
      });
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },
  async actionCreateMacro(context: MainContext, payload: IMacroCreate) {
    try {
      const loadingAlert = { content: "Saving", showProgress: true };
      commitAddAlert(context, loadingAlert);
      const response = (
        await Promise.all([
          api.createMacro(context.state.token, payload),
          new Promise<void>((resolve, _) => setTimeout(() => resolve(), 500)),
        ])
      )[0];
      commitSetMacro(context, response.data);
      commitRemoveAlert(context, loadingAlert);
      commitAddAlert(context, {
        content: "Macro successfully created",
        color: "success",
      });
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },
  async actionCreatePrinterMacro(context: MainContext, payload: IPrinterMacroCreate) {
    try {
      const response = (
        await Promise.all([
          api.createPrinterMacro(context.state.token, payload),
          new Promise<void>((resolve, _) => setTimeout(() => resolve(), 500)),
        ])
      )[0];
      if (response.data) {
        return response.data;
      }
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },
  async actionDeleteMacro(context: MainContext, id: number) {
    try {
      const loadingAlert = { content: "Deleting...", showProgress: true };
      commitAddAlert(context, loadingAlert);
      const response = await api.deleteMacro(context.state.token, id);
      if (response.data) {
        commitRemoveAlert(context, loadingAlert);
        return response.data;
      }
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },
  async actionDeletePrinterMacro(context: MainContext, id: number) {
    try {
      const response = await api.deletePrinterMacro(context.state.token, id);
      if (response.data) {
        return response.data;
      }
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },
  // Material
  async actionGetMaterials(context: MainContext) {
    try {
      const response = await api.getMaterials(context.state.token);
      if (response.data) {
        commitSetMaterials(context, response.data);
      }
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },
  async actionUpdateMaterial(
    context: MainContext,
    payload: { id: number; material: IMaterialCreate },
  ) {
    try {
      const loadingAlert = { content: "Saving", showProgress: true };
      commitAddAlert(context, loadingAlert);
      const response = (
        await Promise.all([
          api.updateMaterial(context.state.token, payload.id, payload.material),
          new Promise<void>((resolve, _) => setTimeout(() => resolve(), 500)),
        ])
      )[0];
      commitSetMaterial(context, response.data);
      commitRemoveAlert(context, loadingAlert);
      commitAddAlert(context, {
        content: "Material successfully updated",
        color: "success",
      });
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },
  async actionCreateMaterial(context: MainContext, payload: IMaterialCreate) {
    try {
      const loadingAlert = { content: "Saving", showProgress: true };
      commitAddAlert(context, loadingAlert);
      const response = (
        await Promise.all([
          api.createMaterial(context.state.token, payload),
          new Promise<void>((resolve, _) => setTimeout(() => resolve(), 500)),
        ])
      )[0];
      commitSetMaterial(context, response.data);
      commitRemoveAlert(context, loadingAlert);
      commitAddAlert(context, {
        content: "Material successfully created",
        color: "success",
      });
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },
  async actionDeleteMaterial(context: MainContext, id: number) {
    try {
      const loadingAlert = { content: "Deleting...", showProgress: true };
      commitAddAlert(context, loadingAlert);
      const response = await api.deleteMaterial(context.state.token, id);
      if (response.data) {
        commitRemoveAlert(context, loadingAlert);
        return response.data;
      }
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },
  // #region Tags
  async actionGetTags(context: MainContext) {
    try {
      const response = await api.getTags(context.state.token);
      if (response.data) {
        commitSetTags(context, response.data);
        return response.data;
      }
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },
  async actionGetTag(context: MainContext, id: number) {
    try {
      const response = await api.getTag(context.state.token, id);
      if (response.data) {
        commitSetTag(context, response.data);
        return response.data;
      }
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },
  async actionCreateTag(context: MainContext, payload: ITagCreate) {
    try {
      const response = await api.createTag(context.state.token, payload);
      if (response.data) {
        commitSetTag(context, response.data);
        return response.data;
      }
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },
  async actionUpdateTag(
    context: MainContext,
    payload: { id: number; tag: ITagUpdate },
  ) {
    try {
      const response = await api.updateTag(
        context.state.token,
        payload.id,
        payload.tag,
      );
      if (response.data) {
        commitSetTag(context, response.data);
        return response.data;
      }
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },
  async actionDeleteTag(context: MainContext, id: number) {
    try {
      const response = await api.deleteTag(context.state.token, id);
      if (response.data) {
        commitDeleteTag(context, response.data);
        return response.data;
      }
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },
  // #endregion
  // Plugin
  async actionGetPluginVersions(context: MainContext) {
    try {
      const response = await api.getPluginVersions(context.state.token);
      if (response.data) {
        commitSetPluginVersions(context, response.data);
      }
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },
  // Misc
  async actionGetTaskStatus(context: MainContext, payload: string) {
    try {
      const response = await api.getTaskStatus(context.state.token, payload);
      if (response.data) {
        commitSetTask(context, response.data);
        return response.data;
      }
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },
  async actionGetTaskIds(context: MainContext) {
    try {
      const response = await api.getTaskIds(context.state.token);
      if (response.data) {
        return response.data;
      }
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },
  // Websocket
  async wsHome(
    context: MainContext,
    payload: { sock: WebSocket; token: string; axes: string[] },
  ) {
    const data = {
      token: payload["token"],
      interface: "client",
      motion: {
        cmd: "home",
        axes: payload["axes"],
      },
    };
    payload["sock"].send(JSON.stringify(data));
  },
  async wsMove(
    context: MainContext,
    payload: { sock: WebSocket; token: string; axes: object },
  ) {
    const data = {
      token: payload["token"],
      interface: "client",
      motion: {
        cmd: "move",
        axes: payload["axes"],
      },
    };
    payload["sock"].send(JSON.stringify(data));
  },
  async wsExtrude(
    context: MainContext,
    payload: { sock: WebSocket; token: string; axes: string[]; value: number },
  ) {
    const data = {
      token: payload["token"],
      interface: "client",
      motion: {
        cmd: "extrude",
        axes: payload["axes"],
        value: payload["value"],
      },
    };
    payload["sock"].send(JSON.stringify(data));
  },
  async wsTemperature(
    context: MainContext,
    payload: { sock: WebSocket; token: string; heater: string; value: number },
  ) {
    const data = {
      token: payload["token"],
      interface: "client",
      temperature: {
        cmd: "temperature",
        heater: payload["heater"],
        value: payload["value"],
      },
    };
    payload["sock"].send(JSON.stringify(data));
  },
  async wsConnectSerial(
    context: MainContext,
    payload: { sock: WebSocket; token: string },
  ) {
    const data = {
      token: payload["token"],
      interface: "client",
      execute: {
        cmd: "connect",
      },
    };
    payload["sock"].send(JSON.stringify(data));
  },
  async wsFiles(
    context: MainContext,
    payload: {
      sock: WebSocket;
      token: string;
      cmd: string;
      file: string;
      content: string | null;
      type: string | null;
    },
  ) {
    const data = {
      token: payload["token"],
      interface: "client",
      files: {
        cmd: payload["cmd"],
        file: payload["file"],
        content: payload["content"],
        loc: "local", // TODO: Support SD cards in future
        type: payload["type"],
      },
    };
    payload["sock"].send(JSON.stringify(data));
  },
  async wsUpdatePlugin(
    context: MainContext,
    payload: {
      sock: WebSocket;
      token: string;
      update_url: string;
      release_tag: string;
    },
  ) {
    const data = {
      token: payload["token"],
      interface: "client",
      update: true,
      update_url: payload["update_url"],
      release_tag: payload["release_tag"],
    };
    payload["sock"].send(JSON.stringify(data));
  },
  async wsSendFileToMachine(
    context: MainContext,
    payload: { sock: WebSocket; token: string; filename: string; url: string },
  ) {
    const data = {
      token: payload["token"],
      interface: "client",
      files: {
        cmd: "upload",
        file: payload["filename"],
        url: payload["url"],
        loc: "local",
        type: "gcode",
      },
    };
    payload["sock"].send(JSON.stringify(data));
  },
  async wsSendFileToMachineAndPrint(
    context: MainContext,
    payload: { sock: WebSocket; token: string; filename: string; url: string },
  ) {
    const data = {
      token: payload["token"],
      interface: "client",
      files: {
        cmd: "upload",
        file: payload["filename"],
        url: payload["url"],
        loc: "local",
        type: "gcode",
        print: true,
      },
    };
    payload["sock"].send(JSON.stringify(data));
  },
  async wsExecute(
    context: MainContext,
    payload: { sock: WebSocket; token: string; cmd: string },
  ) {
    const data = {
      token: payload["token"],
      interface: "client",
      execute: {
        cmd: payload["cmd"],
      },
    };
    payload["sock"].send(JSON.stringify(data));
  },
  async wsGcode(
    context: MainContext,
    payload: { sock: WebSocket; token: string; lines: string[] },
  ) {
    const data = {
      token: payload["token"],
      interface: "client",
      gcode: {
        cmd: "send",
        lines: payload["lines"],
      },
    };
    payload["sock"].send(JSON.stringify(data));
  },
  async wsWebRTC(
    context: MainContext,
    payload: {
      sock: WebSocket;
      token: string;
      auth_key: string;
      cmd: string;
      data: object | null;
    },
  ) {
    const data = {
      token: payload["token"],
      auth_key: payload["auth_key"],
      interface: "client",
      webrtc: payload["cmd"],
      data: payload["data"],
    };
    payload["sock"].send(JSON.stringify(data));
  },
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const { dispatch } = getStoreAccessors<MainState | any, State>("");

export const dispatchCheckApiError = dispatch(actions.actionCheckApiError);
// *** User ***
export const dispatchCheckLoggedIn = dispatch(actions.actionCheckLoggedIn);
export const dispatchGetUserProfile = dispatch(actions.actionGetUserProfile);
export const dispatchCreateNewUser = dispatch(actions.actionCreateNewUser);
export const dispatchLogIn = dispatch(actions.actionLogIn);
export const dispatchLogOut = dispatch(actions.actionLogOut);
export const dispatchUserLogOut = dispatch(actions.actionUserLogOut);
export const dispatchRemoveLogIn = dispatch(actions.actionRemoveLogIn);
export const dispatchRouteLoggedIn = dispatch(actions.actionRouteLoggedIn);
export const dispatchRouteLogOut = dispatch(actions.actionRouteLogOut);
export const dispatchUpdateUserProfile = dispatch(actions.actionUpdateUserProfile);
export const dispatchPasswordRecovery = dispatch(actions.passwordRecovery);
export const dispatchResetPassword = dispatch(actions.resetPassword);
export const dispatchGetUserWebsocket = dispatch(actions.actionGetUserWebsocket);
// *** Alert ***
export const dispatchRemoveAlert = dispatch(actions.removeAlert);
// *** Notification ***
export const dispatchGetNotifications = dispatch(actions.actionGetNotifications);
export const dispatchMarkViewedNotifications = dispatch(
  actions.actionMarkViewedNotifications,
);
export const dispatchDismissNotifications = dispatch(
  actions.actionDismissNotifications,
);
// *** Announcement ***
export const dispatchGetAnnouncements = dispatch(actions.actionGetAnnouncements);
// *** Printer ***
export const dispatchGetPrinters = dispatch(actions.actionGetPrinters);
export const dispatchGetPrinter = dispatch(actions.actionGetPrinter);
export const dispatchUpdatePrinter = dispatch(actions.actionUpdatePrinter);
export const dispatchCreatePrinter = dispatch(actions.actionCreatePrinter);
export const dispatchDeletePrinter = dispatch(actions.actionDeletePrinter);
export const dispatchCreatePrinterTag = dispatch(actions.actionCreatePrinterTag);
export const dispatchDeletePrinterTag = dispatch(actions.actionDeletePrinterTag);
// *** Print job ***
export const dispatchGetPrintJobs = dispatch(actions.actionGetPrintJobs);
export const dispatchGetPrintJobSuccessRate = dispatch(
  actions.actionGetPrintJobSuccessRate,
);
export const dispatchUpdatePrintJob = dispatch(actions.actionUpdatePrintJob);
export const dispatchPostPrintJobZip = dispatch(actions.actionPostPrintJobZip);
export const dispatchGetPrintJobGcode = dispatch(actions.actionGetPrintJobGcode);
export const dispatchGetPrintJobCSV = dispatch(actions.actionGetPrintJobCSV);
export const dispatchGetPrintJobData = dispatch(actions.actionGetPrintJobData);
export const dispatchPostAnalysePrintJob = dispatch(actions.actionPostAnalysePrintJob);
export const dispatchDeletePrintJob = dispatch(actions.actionDeletePrintJob);
// *** CAXTON ***
export const dispatchPostHeatmap = dispatch(actions.actionPostHeatmap);
// *** Error event ***
export const dispatchGetErrorEvents = dispatch(actions.actionGetErrorEvents);
// *** Image item ***
export const dispatchGetImageItems = dispatch(actions.actionGetImageItems);
export const dispatchGetImageItemCount = dispatch(actions.actionGetImageItemCount);
export const dispatchGetImageItemCountByDateRange = dispatch(
  actions.actionGetImageItemCountByDateRange,
);
export const dispatchGetPrintJobMiddleImageItem = dispatch(
  actions.actionGetPrintJobMiddleImageItem,
);
export const dispatchGetPrintJobImageItem = dispatch(
  actions.actionGetPrintJobImageItem,
);
export const dispatchGetPrintJobImageItemCount = dispatch(
  actions.actionGetPrintJobImageItemCount,
);
// *** File ***
export const dispatchGetFiles = dispatch(actions.actionGetFiles);
export const dispatchPostFile = dispatch(actions.actionPostFile);
export const dispatchUpdateFile = dispatch(actions.actionUpdateFile);
export const dispatchDeleteFile = dispatch(actions.actionDeleteFile);
export const dispatchDeleteAllFiles = dispatch(actions.actionDeleteAllFiles);
export const dispatchPostMakeDirectory = dispatch(actions.actionPostMakeDirectory);
// *** Product ***
export const dispatchGetProducts = dispatch(actions.actionGetProducts);
// *** Pricing ***
export const dispatchGetPricings = dispatch(actions.actionGetPricings);
export const dispatchCreateCheckoutSession = dispatch(
  actions.actionCreateCheckoutSession,
);
export const dispatchCreateCustomerPortalSession = dispatch(
  actions.actionCreateCustomerPortalSession,
);
// *** Subscription ***
export const dispatchGetUserSubscriptions = dispatch(
  actions.actionGetUserSubscriptions,
);
// *** File ***
export const dispatchPostGcodeUpload = dispatch(actions.actionPostGcodeUpload);
// *** Macros ***
export const dispatchGetMacros = dispatch(actions.actionGetMacros);
export const dispatchGetMacro = dispatch(actions.actionGetMacro);
export const dispatchUpdateMacro = dispatch(actions.actionUpdateMacro);
export const dispatchPostMacro = dispatch(actions.actionCreateMacro);
export const dispatchDeleteMacro = dispatch(actions.actionDeleteMacro);
export const dispatchPostPrinterMacro = dispatch(actions.actionCreatePrinterMacro);
export const dispatchDeletePrinterMacro = dispatch(actions.actionDeletePrinterMacro);
// *** Material ***
export const dispatchGetMaterials = dispatch(actions.actionGetMaterials);
export const dispatchUpdateMaterial = dispatch(actions.actionUpdateMaterial);
export const dispatchPostMaterial = dispatch(actions.actionCreateMaterial);
export const dispatchDeleteMaterial = dispatch(actions.actionDeleteMaterial);
// *** Tags ***
export const dispatchGetTags = dispatch(actions.actionGetTags);
export const dispatchGetTag = dispatch(actions.actionGetTag);
export const dispatchCreateTag = dispatch(actions.actionCreateTag);
export const dispatchUpdateTag = dispatch(actions.actionUpdateTag);
export const dispatchDeleteTag = dispatch(actions.actionDeleteTag);
// *** Plugin ***
export const dispatchGetPluginVersions = dispatch(actions.actionGetPluginVersions);
// *** Misc ***
export const dispatchGetTaskStatus = dispatch(actions.actionGetTaskStatus);
export const dispatchGetTaskIds = dispatch(actions.actionGetTaskIds);
// *** WebSocket ***
export const dispatchGetSocket = dispatch(actions.actionGetPrinterSocket);
export const dispatchWSHome = dispatch(actions.wsHome);
export const dispatchWSMove = dispatch(actions.wsMove);
export const dispatchWSExtrude = dispatch(actions.wsExtrude);
export const dispatchWSTemperature = dispatch(actions.wsTemperature);
export const dispatchWSFiles = dispatch(actions.wsFiles);
export const dispatchWSExecute = dispatch(actions.wsExecute);
export const dispatchWSGcode = dispatch(actions.wsGcode);
export const dispatchWSUpdatePlugin = dispatch(actions.wsUpdatePlugin);
export const dispatchWSWebRTC = dispatch(actions.wsWebRTC);
export const dispatchWSSendFileToMachine = dispatch(actions.wsSendFileToMachine);
export const dispatchWSSendFileToMachineAndPrint = dispatch(
  actions.wsSendFileToMachineAndPrint,
);
export const dispatchWSConnectSerial = dispatch(actions.wsConnectSerial);
