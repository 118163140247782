import { api } from "@/api";
import { ActionContext } from "vuex";
import {
  IStripePricingCreateAdmin,
  IStripePricingUpdateAdmin,
  IStripeProductCreateAdmin,
  IStripeProductUpdateAdmin,
  IStripeSubscriptionUpdateAdmin,
  IUserProfileCreate,
  IUserProfileUpdate,
  IAnnouncementUpdateAdmin,
  IAnnouncementCreateAdmin,
} from "@/interfaces";
import { State } from "../state";
import { AdminState } from "./state";
import { getStoreAccessors } from "typesafe-vuex";
import {
  commitAdminSetUsers,
  commitAdminSetUser,
  commitAdminSetPrinters,
  commitAdminSetPrintJobs,
  commitAdminSetImageItems,
  commitAdminSetProducts,
  commitAdminSetProduct,
  commitAdminSetSubscriptions,
  commitAdminSetSubscription,
  commitAdminSetPricings,
  commitAdminSetPricing,
  commitAdminSetImageItemCount,
  commitAdminSetAnnouncement,
  commitAdminSetAnnouncements,
} from "./mutations";
import { dispatchCheckApiError } from "../main/actions";
import { commitAddAlert, commitRemoveAlert } from "../main/mutations";

type MainContext = ActionContext<AdminState, State>;

export const actions = {
  async actionAdminGetUsers(context: MainContext) {
    try {
      const response = await api.getUsers(context.rootState.main.token);
      if (response) {
        commitAdminSetUsers(context, response.data);
      }
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },
  async actionAdminUpdateUser(
    context: MainContext,
    payload: { id: number; user: IUserProfileUpdate },
  ) {
    try {
      const loadingAlert = { content: "Saving", showProgress: true };
      commitAddAlert(context, loadingAlert);
      const response = (
        await Promise.all([
          api.updateUser(context.rootState.main.token, payload.id, payload.user),
          await new Promise<void>((resolve, _) => setTimeout(() => resolve(), 500)),
        ])
      )[0];
      commitAdminSetUser(context, response.data);
      commitRemoveAlert(context, loadingAlert);
      commitAddAlert(context, {
        content: "User successfully updated",
        color: "success",
      });
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },
  async actionAdminCreateUser(context: MainContext, payload: IUserProfileCreate) {
    try {
      const loadingAlert = { content: "Saving", showProgress: true };
      commitAddAlert(context, loadingAlert);
      const response = (
        await Promise.all([
          api.createUser(context.rootState.main.token, payload),
          await new Promise<void>((resolve, _) => setTimeout(() => resolve(), 500)),
        ])
      )[0];
      commitAdminSetUser(context, response.data);
      commitRemoveAlert(context, loadingAlert);
      commitAddAlert(context, {
        content: "User successfully created",
        color: "success",
      });
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },
  async actionAdminDeleteUser(context: MainContext, payload) {
    try {
      const loadingAlert = { content: "Deleting...", showProgress: true };
      commitAddAlert(context, loadingAlert);
      const response = await api.deleteUser(context.rootState.main.token, payload);
      if (response.data) {
        commitRemoveAlert(context, loadingAlert);
        return response.data;
      }
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },
  async actionAdminGetPrinters(context: MainContext) {
    try {
      const response = await api.getAllPrinters(context.rootState.main.token);
      if (response) {
        commitAdminSetPrinters(context, response.data);
      }
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },
  async actionAdminGetPrintJobs(
    context: MainContext,
    payload: { limit?: number; sort_by?: string; sort_desc?: boolean },
  ) {
    try {
      const response = await api.getAllPrintJobs(
        context.rootState.main.token,
        payload?.limit,
        payload?.sort_by,
        payload?.sort_desc,
      );
      if (response) {
        commitAdminSetPrintJobs(context, response.data);
      }
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },
  async actionAdminGetImageItems(
    context: MainContext,
    payload: {
      id: number;
      raw?: boolean;
    },
  ) {
    try {
      const response = await api.adminGetPrintJobImageItems(
        context.rootState.main.token,
        payload.id,
        payload.raw,
      );
      if (response) {
        commitAdminSetImageItems(context, response.data);
      }
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },
  async actionAdminGetImageItemCount(context: MainContext) {
    try {
      const response = await api.getImageItemCount(context.rootState.main.token);
      if (response.data !== undefined) {
        commitAdminSetImageItemCount(context, response.data);
      }
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },
  async actionAdminGetImageItemCountByDateRange(
    context: MainContext,
    payload: {
      startDate: string;
      endDate: string;
    },
  ) {
    try {
      const response = await api.getImageItemCountByDateRange(
        context.rootState.main.token,
        payload.startDate, // note: this is a str in the format YYYY-MM-DD
        payload.endDate, // note: this is a str in the format YYYY-MM-DD
      );
      if (response.data !== undefined) {
        commitAdminSetImageItemCount(context, response.data);
      }
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },
  async actionAdminGetProducts(context: MainContext) {
    try {
      const response = await api.getAdminProducts(context.rootState.main.token);
      if (response) {
        commitAdminSetProducts(context, response.data);
      }
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },
  async actionAdminCreateProduct(
    context: MainContext,
    payload: IStripeProductCreateAdmin,
  ) {
    try {
      const loadingAlert = { content: "Saving", showProgress: true };
      commitAddAlert(context, loadingAlert);
      const response = (
        await Promise.all([
          api.createProduct(context.rootState.main.token, payload),
          await new Promise<void>((resolve, _) => setTimeout(() => resolve(), 500)),
        ])
      )[0];
      commitAdminSetUser(context, response.data);
      commitRemoveAlert(context, loadingAlert);
      commitAddAlert(context, {
        content: "Product successfully created",
        color: "success",
      });
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },
  async actionAdminUpdateProduct(
    context: MainContext,
    payload: {
      id: number;
      product: IStripeProductUpdateAdmin;
    },
  ) {
    try {
      const loadingAlert = { content: "Saving", showProgress: true };
      commitAddAlert(context, loadingAlert);
      const response = (
        await Promise.all([
          api.updateProduct(context.rootState.main.token, payload.id, payload.product),
          await new Promise<void>((resolve, _) => setTimeout(() => resolve(), 500)),
        ])
      )[0];
      commitAdminSetProduct(context, response.data);
      commitRemoveAlert(context, loadingAlert);
      commitAddAlert(context, {
        content: "Product successfully updated",
        color: "success",
      });
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },
  async actionAdminDeleteProduct(context: MainContext, payload) {
    try {
      const loadingAlert = { content: "Deleting...", showProgress: true };
      commitAddAlert(context, loadingAlert);
      const response = await api.deleteProduct(context.rootState.main.token, payload);
      if (response.data) {
        commitRemoveAlert(context, loadingAlert);
        return response.data;
      }
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },
  async actionAdminGetPricings(context: MainContext) {
    try {
      const response = await api.getAdminPricings(context.rootState.main.token);
      if (response) {
        commitAdminSetPricings(context, response.data);
      }
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },
  async actionAdminCreatePricing(
    context: MainContext,
    payload: IStripePricingCreateAdmin,
  ) {
    try {
      const loadingAlert = { content: "Saving", showProgress: true };
      commitAddAlert(context, loadingAlert);
      const response = (
        await Promise.all([
          api.createPricing(context.rootState.main.token, payload),
          await new Promise<void>((resolve, _) => setTimeout(() => resolve(), 500)),
        ])
      )[0];
      commitAdminSetPricing(context, response.data);
      commitRemoveAlert(context, loadingAlert);
      commitAddAlert(context, {
        content: "Product successfully created",
        color: "success",
      });
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },
  async actionAdminUpdatePricing(
    context: MainContext,
    payload: {
      id: number;
      pricing: IStripePricingUpdateAdmin;
    },
  ) {
    try {
      const loadingAlert = { content: "Saving", showProgress: true };
      commitAddAlert(context, loadingAlert);
      const response = (
        await Promise.all([
          api.updatePricing(context.rootState.main.token, payload.id, payload.pricing),
          await new Promise<void>((resolve, _) => setTimeout(() => resolve(), 500)),
        ])
      )[0];
      commitAdminSetPricing(context, response.data);
      commitRemoveAlert(context, loadingAlert);
      commitAddAlert(context, {
        content: "Pricing successfully updated",
        color: "success",
      });
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },
  async actionAdminDeletePricing(context: MainContext, payload) {
    try {
      const loadingAlert = { content: "Deleting...", showProgress: true };
      commitAddAlert(context, loadingAlert);
      const response = await api.deletePricing(context.rootState.main.token, payload);
      if (response.data) {
        commitRemoveAlert(context, loadingAlert);
        return response.data;
      }
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },
  async actionAdminGetSubscriptions(context: MainContext) {
    try {
      const response = await api.getAllSubscriptions(context.rootState.main.token);
      if (response) {
        commitAdminSetSubscriptions(context, response.data);
      }
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },
  async actionAdminUpdateSubscription(
    context: MainContext,
    payload: {
      id: number;
      subscription: IStripeSubscriptionUpdateAdmin;
    },
  ) {
    try {
      const loadingAlert = { content: "Saving", showProgress: true };
      commitAddAlert(context, loadingAlert);
      const response = (
        await Promise.all([
          api.updateSubscription(
            context.rootState.main.token,
            payload.id,
            payload.subscription,
          ),
          await new Promise<void>((resolve, _) => setTimeout(() => resolve(), 500)),
        ])
      )[0];
      commitAdminSetSubscription(context, response.data);
      commitRemoveAlert(context, loadingAlert);
      commitAddAlert(context, {
        content: "Subscription successfully updated",
        color: "success",
      });
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },
  async actionAdminDeleteSubscription(context: MainContext, payload) {
    try {
      const loadingAlert = { content: "Deleting...", showProgress: true };
      commitAddAlert(context, loadingAlert);
      const response = await api.deleteSubscription(
        context.rootState.main.token,
        payload,
      );
      if (response.data) {
        commitRemoveAlert(context, loadingAlert);
        return response.data;
      }
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },
  async actionAdminGetAnnouncements(context: MainContext) {
    try {
      const response = await api.getAdminAnnouncements(context.rootState.main.token);
      if (response) {
        commitAdminSetAnnouncements(context, response.data);
      }
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },
  async actionAdminCreateAnnouncement(
    context: MainContext,
    payload: IAnnouncementCreateAdmin,
  ) {
    try {
      const loadingAlert = { content: "Saving", showProgress: true };
      commitAddAlert(context, loadingAlert);
      const response = (
        await Promise.all([
          api.createAnnouncement(context.rootState.main.token, payload),
          await new Promise<void>((resolve, _) => setTimeout(() => resolve(), 500)),
        ])
      )[0];
      commitAdminSetAnnouncement(context, response.data);
      commitRemoveAlert(context, loadingAlert);
      commitAddAlert(context, {
        content: "Announcement successfully created",
        color: "success",
      });
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },
  async actionAdminUpdateAnnouncement(
    context: MainContext,
    payload: {
      id: number;
      announcement: IAnnouncementUpdateAdmin;
    },
  ) {
    try {
      const loadingAlert = { content: "Saving", showProgress: true };
      commitAddAlert(context, loadingAlert);
      const response = (
        await Promise.all([
          api.updateAnnouncement(
            context.rootState.main.token,
            payload.id,
            payload.announcement,
          ),
          await new Promise<void>((resolve, _) => setTimeout(() => resolve(), 500)),
        ])
      )[0];
      commitAdminSetAnnouncement(context, response.data);
      commitRemoveAlert(context, loadingAlert);
      commitAddAlert(context, {
        content: "Subscription successfully updated",
        color: "success",
      });
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },
  async actionAdminDeleteAnnouncement(context: MainContext, payload) {
    try {
      const loadingAlert = { content: "Deleting...", showProgress: true };
      commitAddAlert(context, loadingAlert);
      const response = await api.deleteAnnouncement(
        context.rootState.main.token,
        payload,
      );
      if (response.data) {
        commitRemoveAlert(context, loadingAlert);
        return response.data;
      }
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },
};

const { dispatch } = getStoreAccessors<AdminState, State>("");

// *** User ***
export const dispatchAdminCreateUser = dispatch(actions.actionAdminCreateUser);
export const dispatchAdminGetUsers = dispatch(actions.actionAdminGetUsers);
export const dispatchAdminUpdateUser = dispatch(actions.actionAdminUpdateUser);
export const dispatchAdminDeleteUser = dispatch(actions.actionAdminDeleteUser);
// *** Printer ***
export const dispatchAdminGetPrinters = dispatch(actions.actionAdminGetPrinters);
// *** Print Job ***
export const dispatchAdminGetPrintJobs = dispatch(actions.actionAdminGetPrintJobs);
// *** Image Item ***
export const dispatchAdminGetImageItems = dispatch(actions.actionAdminGetImageItems);
export const dispatchAdminGetImageItemCount = dispatch(
  actions.actionAdminGetImageItemCount,
);
export const dispatchAdminGetImageItemCountByDateRange = dispatch(
  actions.actionAdminGetImageItemCountByDateRange,
);
// *** Product ***
export const dispatchAdminCreateProduct = dispatch(actions.actionAdminCreateProduct);
export const dispatchAdminGetProducts = dispatch(actions.actionAdminGetProducts);
export const dispatchAdminUpdateProduct = dispatch(actions.actionAdminUpdateProduct);
export const dispatchAdminDeleteProduct = dispatch(actions.actionAdminDeleteProduct);
// *** Pricing ***
export const dispatchAdminGetPricings = dispatch(actions.actionAdminGetPricings);
export const dispatchAdminCreatePricing = dispatch(actions.actionAdminCreatePricing);
export const dispatchAdminUpdatePricing = dispatch(actions.actionAdminUpdatePricing);
export const dispatchAdminDeletePricing = dispatch(actions.actionAdminDeletePricing);
// *** Subscription ***
export const dispatchAdminGetSubscriptions = dispatch(
  actions.actionAdminGetSubscriptions,
);
export const dispatchAdminUpdateSubscription = dispatch(
  actions.actionAdminUpdateSubscription,
);
export const dispatchAdminDeleteSubscription = dispatch(
  actions.actionAdminDeleteSubscription,
);
// *** Announcement ***
export const dispatchAdminGetAnnouncements = dispatch(
  actions.actionAdminGetAnnouncements,
);
export const dispatchAdminCreateAnnouncement = dispatch(
  actions.actionAdminCreateAnnouncement,
);
export const dispatchAdminUpdateAnnouncement = dispatch(
  actions.actionAdminUpdateAnnouncement,
);
export const dispatchAdminDeleteAnnouncement = dispatch(
  actions.actionAdminDeleteAnnouncement,
);
