// Determine the current environment from environment variables
const env = process.env.VUE_APP_ENV;
const s3Tag = process.env.VUE_APP_S3_TAG;

// Initialize variables for API and WebSocket URLs
let envApiUrl = "";
let envWsUrl = "";

// Set the appropriate API and WebSocket URLs based on the environment
if (env === "production") {
  envApiUrl = `https://${process.env.VUE_APP_DOMAIN_PROD}`;
  envWsUrl = `wss://${process.env.VUE_APP_DOMAIN_PROD}`;
} else if (env === "staging") {
  envApiUrl = `https://${process.env.VUE_APP_DOMAIN_STAG}`;
  envWsUrl = `wss://${process.env.VUE_APP_DOMAIN_STAG}`;
} else {
  envApiUrl = `http://${process.env.VUE_APP_DOMAIN_DEV}`;
  envWsUrl = `ws://${process.env.VUE_APP_DOMAIN_DEV}`;
}

// Export the configured URLs and app settings for use throughout the application
export const apiUrl = envApiUrl;
export const wsUrl = envWsUrl;
export const appName = process.env.VUE_APP_NAME;
export const envName = env;
export const s3TagName = s3Tag;
