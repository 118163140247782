// Import component hooks to be used globally
import "./component-hooks";

// Import core Vue and additional plugins and configurations
import Vue from "vue";
import vuetify from "./plugins/vuetify"; // UI framework
import App from "./App.vue"; // Root component
import router from "./router"; // Application routing
import store from "@/store"; // Vuex state management
import "./registerServiceWorker"; // Service worker for offline capabilities

// Disable production tip messages in the console
Vue.config.productionTip = false;

/**
 * Create and mount the root Vue instance.
 * This instance integrates the router, store, Vuetify, and renders the root App component.
 */
new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app"); // Mount to DOM element with id "app"
