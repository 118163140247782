import { AdminState } from "./state";
import { getStoreAccessors } from "typesafe-vuex";
import { State } from "../state";
import { slugify } from "@/utils";

export const getters = {
  adminUsers: (state: AdminState) => state.adminUsers,
  adminOneUser: (state: AdminState) => (userId: number) => {
    const filteredUsers = state.adminUsers.filter((user) => user.id === userId);
    if (filteredUsers.length > 0) {
      return { ...filteredUsers[0] };
    }
  },
  adminPrinters: (state: AdminState) => state.adminPrinters,
  adminOnePrinter: (state: AdminState) => (printerId: number) => {
    const filteredPrinters = state.adminPrinters.filter(
      (printer) => printer.id === printerId,
    );
    if (filteredPrinters.length > 0) {
      return { ...filteredPrinters[0] };
    }
  },
  adminPrintJobs: (state: AdminState) => state.adminPrintJobs,
  adminOnePrintJob: (state: AdminState) => (printJobSlug: string) => {
    const filteredPrintJobs = state.adminPrintJobs.filter(
      (job) => slugify(job.long_name) === printJobSlug,
    );
    if (filteredPrintJobs.length > 0) {
      return { ...filteredPrintJobs[0] };
    }
  },
  adminOnePrintJobFromId: (state: AdminState) => (printJobId: number) => {
    const filteredPrintJobs = state.adminPrintJobs.filter(
      (job) => job.id === printJobId,
    );
    if (filteredPrintJobs.length > 0) {
      return { ...filteredPrintJobs[0] };
    }
  },
  adminImageItems: (state: AdminState) => state.adminImageItems,
  adminImageItemCount: (state: AdminState) => state.adminImageItemCount,
  adminProducts: (state: AdminState) => state.adminStripeProducts,
  adminOneProduct: (state: AdminState) => (productId: number) => {
    const filteredProducts = state.adminStripeProducts.filter(
      (product) => product.id === productId,
    );
    if (filteredProducts.length > 0) {
      return { ...filteredProducts[0] };
    }
  },
  adminSubscriptions: (state: AdminState) => state.adminStripeSubscriptions,
  adminOneSubscription: (state: AdminState) => (subscriptionId: number) => {
    const filteredSubscriptions = state.adminStripeSubscriptions.filter(
      (subscription) => subscription.id === subscriptionId,
    );
    if (filteredSubscriptions.length > 0) {
      return { ...filteredSubscriptions[0] };
    }
  },
  adminPricings: (state: AdminState) => state.adminStripePricings,
  adminOnePricing: (state: AdminState) => (pricingId: number) => {
    const filteredPricings = state.adminStripePricings.filter(
      (pricing) => pricing.id === pricingId,
    );
    if (filteredPricings.length > 0) {
      return { ...filteredPricings[0] };
    }
  },
  adminAnnouncements: (state: AdminState) => state.adminAnnouncements,
  adminOneAnnouncement: (state: AdminState) => (announcementId: number) => {
    const filteredAnnouncements = state.adminAnnouncements.filter(
      (announcement) => announcement.id === announcementId,
    );
    if (filteredAnnouncements.length > 0) {
      return { ...filteredAnnouncements[0] };
    }
  },
};

const { read } = getStoreAccessors<AdminState, State>("");

export const readAdminOneUser = read(getters.adminOneUser);
export const readAdminUsers = read(getters.adminUsers);
export const readAdminPrinters = read(getters.adminPrinters);
export const readAdminOnePrinter = read(getters.adminOnePrinter);
export const readAdminPrintJobs = read(getters.adminPrintJobs);
export const readAdminOnePrintJob = read(getters.adminOnePrintJob);
export const readAdminOnePrintJobFromId = read(getters.adminOnePrintJobFromId);
export const readAdminImageItems = read(getters.adminImageItems);
export const readAdminImageItemCount = read(getters.adminImageItemCount);
export const readAdminOneProduct = read(getters.adminOneProduct);
export const readAdminProducts = read(getters.adminProducts);
export const readAdminSubscriptions = read(getters.adminSubscriptions);
export const readAdminOneSubscription = read(getters.adminOneSubscription);
export const readAdminPricings = read(getters.adminPricings);
export const readAdminOnePricing = read(getters.adminOnePricing);
export const readAdminAnnouncements = read(getters.adminAnnouncements);
export const readAdminOneAnnouncement = read(getters.adminOneAnnouncement);
