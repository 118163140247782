import { dispatchGetTaskStatus, dispatchGetPrintJobs } from "@/store/main/actions";
import {
  commitRemoveTask,
  commitRemoveTaskByID,
  commitAddAlert,
} from "@/store/main/mutations";
import { ITag } from "./interfaces";

/**
 * Retrieves the token from the browser's local storage.
 * @returns {string | null} The token if it exists, otherwise null.
 */
export const getLocalToken = (): string | null => localStorage.getItem("token");

/**
 * Saves a token to the browser's local storage.
 * @param {string} token - The token to be saved.
 */
export const saveLocalToken = (token: string): void =>
  localStorage.setItem("token", token);

/**
 * Removes the token from the browser's local storage.
 */
export const removeLocalToken = (): void => localStorage.removeItem("token");

/**
 * Converts a string into a URL-friendly slug.
 * This function replaces spaces with dashes, removes special characters, and normalizes the string.
 * @param {string} str - The input string to be slugified.
 * @returns {string} The slugified string.
 */
export function slugify(str: string): string {
  const specialChars =
    "àáâäæãåāăąçćčđďèéêëēėęěğǵḧîïíīįìıİłḿñńǹňôöòóœøōõőṕŕřßśšşșťțûüùúūǘůűųẃẍÿýžźż·/_,:;";
  const replacementChars =
    "aaaaaaaaaacccddeeeeeeeegghiiiiiiiilmnnnnoooooooooprrsssssttuuuuuuuuuwxyyzzz------";
  const regexPattern = new RegExp(specialChars.split("").join("|"), "g");

  return str
    .toString()
    .toLowerCase()
    .replace(/\s+/g, "-") // Replace spaces with dashes
    .replace(regexPattern, (c) => replacementChars.charAt(specialChars.indexOf(c))) // Replace special characters
    .replace(/&/g, "-and-") // Replace & with 'and'
    .replace(/[^\w-]+/g, "") // Remove all non-word characters
    .replace(/--+/g, "-") // Replace multiple dashes with a single dash
    .replace(/^-+/, "") // Trim dashes from the start of the string
    .replace(/-+$/, ""); // Trim dashes from the end of the string
}

/**
 * Converts a string to title case.
 * @param {string} str - The input string to be converted to title case.
 * @returns {string} The title cased string.
 */
export function toTitleCase(str: string): string {
  return str
    .toLowerCase()
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
}

/**
 * Converts a string to sentence case.
 * @param {string} str - The input string to be converted to sentence case.
 * @returns {string} The sentence cased string.
 */
export function toSentenceCase(str: string): string {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

/**
 * Converts a snake_case string to a sentence case string.
 * @param {string} str - The input string to be converted to sentence case.
 * @returns {string} The sentence cased string.
 */
export function snakeToSentence(str: string): string {
  return str
    .split("_")
    .map((word) => toSentenceCase(word))
    .join(" ");
}

// #region Tasks
/**
 * Polls the task status from the server and performs corresponding actions based on the result.
 * Removes the task if it's completed successfully or failed and updates the store accordingly.
 * @param {any} store - The Vuex store instance.
 * @param {string} task_id - The ID of the task to poll.
 * @returns {Promise<boolean>} A promise that resolves to a boolean indicating whether the task completed successfully.
 */
export async function pollTaskStatus(store: any, task_id: string): Promise<boolean> {
  const task = await dispatchGetTaskStatus(store, task_id);

  if (task == null || task == undefined) {
    // Task does not exist, remove it from the store by its ID
    commitRemoveTaskByID(store, task_id);
    return true;
  }

  let success = false;

  if (task["task_result"] === "success") {
    // Remove the completed task from the store
    commitRemoveTask(store, task);
    success = true;

    // Add a success alert to the store
    commitAddAlert(store, {
      content: `${task["task_status"]}`,
      color: "success",
    });

    // Handle specific task names and dispatch relevant actions
    if (task["task_name"] === "uploading_job_zip") {
      await dispatchGetPrintJobs(store);
    }
  } else if (task["task_result"] === "failed") {
    // Remove the failed task from the store
    commitRemoveTask(store, task);
    success = true;

    // Add an error alert to the store
    commitAddAlert(store, {
      content: `${task["task_status"]}`,
      color: "error",
    });
  }

  return success;
}
// #endregion
// #region Tags
export function getTextColor(tag: ITag, selected: boolean): string {
  if (!selected) {
    return "#000000";
  }
  const colour = tag.colour || "#000000";
  const luminance = (hex: string) => {
    const a = [
      parseInt(hex.substr(1, 2), 16),
      parseInt(hex.substr(3, 2), 16),
      parseInt(hex.substr(5, 2), 16),
    ].map((v) => {
      v /= 255;
      return v <= 0.03928 ? v / 12.92 : Math.pow((v + 0.055) / 1.055, 2.4);
    });
    return a[0] * 0.2126 + a[1] * 0.7152 + a[2] * 0.0722;
  };
  return luminance(colour) > 0.5 ? "#000000" : "#ffffff";
}
// #endregion
